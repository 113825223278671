import React, { useState } from "react";
import logo from "../assets/Header_logo.png";
import { Link } from "react-scroll";

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleLinkClick = () => {
        setIsOpen(false);
    };

    return (
        <header className="bg-white shadow-md fixed top-0 left-0 w-full z-50">
            <div className="container mx-auto px-6 flex justify-between items-center py-4">
              
                <div className="flex items-center">
                    <img src={logo} alt="AuditScan Innovations" className="h-10" />
                </div>

             
                <nav className="hidden md:flex space-x-10">
                    <Link
                        to="services" 
                        smooth={true}
                        duration={500}
                        className="text-gray-700 hover:text-gray-900 text-lg font-bold cursor-pointer"
                    >
                        Services
                    </Link>
                    <Link
                        to="solutions"
                        smooth={true}
                        duration={500}
                        className="text-gray-700 hover:text-gray-900 text-lg font-bold cursor-pointer"
                    >
                        Solutions
                    </Link>
                    <Link
                        to="team"
                        smooth={true}
                        duration={500}
                        className="text-gray-700 hover:text-gray-900 text-lg font-bold cursor-pointer"
                    >
                        Team
                    </Link>
                    <Link
                        to="contact-us"
                        smooth={true}
                        duration={500}
                        className="text-gray-700 hover:text-gray-900 text-lg font-bold cursor-pointer"
                    >
                        Contact Us
                    </Link>
                </nav>

                <button
                    className="md:hidden text-gray-700 hover:text-gray-900"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M4 6h16M4 12h16M4 18h16"
                        />
                    </svg>
                </button>

                {/* Mobile Menu */}
                {isOpen && (
                    <div className="absolute top-16 left-0 w-full bg-white shadow-lg md:hidden">
                        <nav className="flex flex-col items-center space-y-4 py-4">
                            <Link
                                to="services"
                                smooth={true}
                                duration={500}
                                onClick={handleLinkClick} 
                                className="text-gray-700 hover:text-gray-900 text-lg font-bold cursor-pointer"
                            >
                                Services
                            </Link>
                            <Link
                                to="solutions"
                                smooth={true}
                                duration={500}
                                onClick={handleLinkClick}
                                className="text-gray-700 hover:text-gray-900 text-lg font-bold cursor-pointer"
                            >
                                Solutions
                            </Link>
                            <Link
                                to="team"
                                smooth={true}
                                duration={500}
                                onClick={handleLinkClick} 
                                className="text-gray-700 hover:text-gray-900 text-lg font-bold cursor-pointer"
                            >
                                Team
                            </Link>
                            <Link
                                to="contact-us"
                                smooth={true}
                                duration={500}
                                onClick={handleLinkClick}
                                className="text-gray-700 hover:text-gray-900 text-lg font-bold cursor-pointer"
                            >
                                Contact Us
                            </Link>
                        </nav>
                    </div>
                )}
            </div>
        </header>
    );
};

export default Header;
