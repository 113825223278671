import React from "react";
import taggingImage from "../assets/AssetTagging.png";
import trackingImage from "../assets/AssetTracking.png";
import lifecycleImage from "../assets/AssetLifecycle.png";

const ServicesSection = () => {
  return (
    <section
      id="services"
      className="bg-white py-12 relative"
      style={{
        paddingTop: "150px", 
        marginTop: "-20px", 
      }}
    >
      <div className="container mx-auto px-6 lg:px-16 text-center">
       
        <h2 className="text-4xl font-bold text-gray-800 mb-4">OUR SERVICES</h2>
        <h3 className="text-2xl font-semibold text-gray-600 mb-6">
          Fixed Asset Management
        </h3>
        <p className="text-lg text-gray-600 mb-12 leading-relaxed">
          It involves the strategic planning, tracking, and optimization of tangible assets owned by an organization, such as buildings, machinery, equipment, and vehicles. Efficient management of fixed assets is crucial for maintaining financial stability, operational efficiency, and regulatory compliance.
        </p>

       
        <div className="flex flex-col lg:flex-row justify-center items-center lg:space-x-12 space-y-8 lg:space-y-0">
         
          <div className="flex flex-col items-center text-center">
            <img
              src={taggingImage}
              alt="Asset Tagging"
              className="w-40 h-40 mb-4 rounded-full shadow-md"
            />
            <h4 className="text-xl font-bold text-gray-800 mb-2">
              Asset Tagging
            </h4>
            <p className="text-gray-600 text-sm leading-relaxed">
              Asset Tagging assigns unique identifiers like QR codes, barcodes, or RFID tags to assets, enabling real-time tracking and efficient management. It streamlines audits, inventory checks, and asset transfers while enhancing security and compliance.
            </p>
          </div>

          <div className="flex flex-col items-center text-center">
            <img
              src={trackingImage}
              alt="Asset Tracking"
              className="w-40 h-40 mb-4 rounded-full shadow-md"
            />
            <h4 className="text-xl font-bold text-gray-800 mb-2">
              Asset Tracking
            </h4>
            <p className="text-gray-600 text-sm leading-relaxed">
              Implementing systems to accurately track the location, condition, and usage of fixed assets throughout their lifecycle. This includes barcode or RFID tagging, asset registers, and asset management software.
            </p>
          </div>

        
          <div className="flex flex-col items-center text-center">
            <img
              src={lifecycleImage}
              alt="Asset Lifecycle Management"
              className="w-40 h-40 mb-4 rounded-full shadow-md"
            />
            <h4 className="text-xl font-bold text-gray-800 mb-2">
              Asset Lifecycle Management
            </h4>
            <p className="text-gray-600 text-sm leading-relaxed">
              Regular maintenance activities, such as inspections, repairs, and upgrades, are documented in the asset management system. The custodian is responsible for updating the asset's location in the system whenever it is relocated within the organization.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
