import React from "react";
import assetManagementImage from "../assets/AssetManagement.png";
import verificationImage from "../assets/Approve.png";
import taggingImage from "../assets/Tagging.png";
import reportingImage from "../assets/Reporting.png";

const SolutionsSection = () => {
    return (
      <section id="solutions" className="bg-white py-12 min-h-screen flex items-center">
        <div className="container mx-auto px-6 lg:px-16 text-center">
         
          <h2 className="text-4xl font-bold text-gray-800 mb-6">OUR SOLUTIONS</h2>
          <h3 className="text-2xl font-semibold text-gray-600 mb-8">
            Powerful Features for Effective Asset Management
          </h3>
  
      
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-5xl mx-auto">
           
            <div className="flex flex-col items-center text-center">
              <img
                src={assetManagementImage}
                alt="Asset Management"
                className="w-28 h-28 mb-4"
              />
              <h4 className="text-xl font-bold text-gray-800 mb-2">
                Asset Management
              </h4>
              <p className="text-gray-600 text-sm leading-relaxed">
                Efficiently track and manage your fixed assets.
              </p>
            </div>
  
            
            <div className="flex flex-col items-center text-center">
              <img
                src={verificationImage}
                alt="Verification"
                className="w-28 h-28 mb-4"
              />
              <h4 className="text-xl font-bold text-gray-800 mb-2">
                Verification
              </h4>
              <p className="text-gray-600 text-sm leading-relaxed">
                Ensure accurate and up-to-date asset information through verification processes.
              </p>
            </div>
  
            <div className="flex flex-col items-center text-center">
              <img
                src={taggingImage}
                alt="Tagging"
                className="w-28 h-28 mb-4"
              />
              <h4 className="text-xl font-bold text-gray-800 mb-2">Tagging</h4>
              <p className="text-gray-600 text-sm leading-relaxed">
                Easily label and identify assets with unique tags for quick retrieval.
              </p>
            </div>
  
            
            <div className="flex flex-col items-center text-center">
              <img
                src={reportingImage}
                alt="Reporting"
                className="w-28 h-28 mb-4"
              />
              <h4 className="text-xl font-bold text-gray-800 mb-2">Reporting</h4>
              <p className="text-gray-600 text-sm leading-relaxed">
                Generate comprehensive reports on assets and depreciation.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  };
export default SolutionsSection;
