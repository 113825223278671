import React from "react";

const ContactUsSection = () => {
  return (
    <section id="contact-us" className="bg-white py-16 min-h-screen flex items-center">
      <div className="container mx-auto px-6 lg:px-16 grid grid-cols-1 md:grid-cols-2 gap-12">
        
        <div className="flex flex-col justify-center">
          <h2 className="text-5xl font-bold text-gray-800 mb-8">Contact Us</h2>
          <div className="mb-8">
            <div className="flex items-center mb-4">
              <svg
                className="w-8 h-8 text-blue-600 mr-3"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.657 16.657L13.414 12l4.243-4.243m-4.95 4.95L6.343 7.343m12.021 12.021a9 9 0 11-12.728 0 9 9 0 0112.728 0z"
                ></path>
              </svg>
              <h4 className="text-2xl font-bold text-gray-800">Delhi</h4>
            </div>
            <p className="text-gray-700 text-lg mb-2">
              5A/15, Ansari Road, Darya Ganj, New Delhi - 110002
            </p>
            <p className="text-gray-700 text-lg font-semibold">+91 9716630321</p>
          </div>

          <div>
            <div className="flex items-center mb-4">
              <svg
                className="w-8 h-8 text-blue-600 mr-3"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.657 16.657L13.414 12l4.243-4.243m-4.95 4.95L6.343 7.343m12.021 12.021a9 9 0 11-12.728 0 9 9 0 0112.728 0z"
                ></path>
              </svg>
              <h4 className="text-2xl font-bold text-gray-800">Ghaziabad</h4>
            </div>
            <p className="text-gray-700 text-lg mb-2">
              E-166, Nandgram, Ghaziabad, U.P. - 201003
            </p>
            <p className="text-gray-700 text-lg font-semibold">+91 8130812821</p>
          </div>
        </div>

        
        <div>
          <iframe
            title="Google Map"
            className="w-full h-[400px] md:h-[500px] rounded-lg shadow-lg"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3498.4419903531713!2d77.23375267530577!3d28.64534218231281!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd1c40c9aafd%3A0xe49a7c28c8df8aa8!2s5A%2F15%2C%20Ansari%20Rd%2C%20Darya%20Ganj%2C%20New%20Delhi%2C%20Delhi%20110002!5e0!3m2!1sen!2sin!4v1700000000000!5m2!1sen!2sin"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default ContactUsSection;
