import React from 'react'
import Header from './components/Header';
import HeaderData from './components/HeaderData';
import ServicesSection from './components/ServiceSection';
import SolutionsSection from './components/SolutionsSection';
import TeamSection from './components/TeamSection';
import ContactUsSection from './components/ContactUsSection';
import Footer from './components/Footer';


function App() {
  return (
    <div>
      <Header />
      <HeaderData />
      <ServicesSection />
      <SolutionsSection />
      <TeamSection />
      <ContactUsSection />
      <Footer />
    </div>
  );
}

export default App; 

