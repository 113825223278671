import React from "react";
import varunImage from "../assets/Varun.jpg";
import lakshayImage from "../assets/lakshay.jpg";
import akshatImage from "../assets/akshat.jpg";
import namanImage from "../assets/naman.png";
import rijoImage from "../assets/rijo.jpg";
import chandraImage from "../assets/chandra.png";

const TeamSection = () => {
    return (
        <section id="team" className="bg-white py-12 relative"
            style={{
                paddingTop: "150px",
                marginTop: "-100px", 
            }}
        >
            <div className="container mx-auto px-6 lg:px-16 text-center">
               
                <h2 className="text-4xl font-bold text-gray-800 mb-6">TEAM & PARTNERS</h2>

               
                <div className="grid grid-cols-1 md:grid-cols-3 gap-12 max-w-6xl mx-auto">
                  
                    <div className="flex flex-col items-center text-center">
                        <img
                            src={varunImage}
                            alt="Varun Pal"
                            className="w-40 h-40 rounded-full mb-4 shadow-lg object-cover"
                        />
                        <h4 className="text-xl font-bold text-gray-800">VARUN PAL</h4>
                        <p className="text-gray-600 text-sm leading-relaxed">
                            Expertise in statutory audits, internal controls, and financial
                            statement preparation, gained at Deloitte.
                        </p>
                    </div>

                    <div className="flex flex-col items-center text-center">
                        <img
                            src={lakshayImage}
                            alt="Lakshay Gupta"
                            className="w-40 h-40 rounded-full mb-4 shadow-lg object-cover"
                        />
                        <h4 className="text-xl font-bold text-gray-800">LAKSHAY GUPTA</h4>
                        <p className="text-gray-600 text-sm leading-relaxed">
                            Ex-Junior Analyst at Rising Capital, VC Firm. Also worked as
                            Senior Partnership Manager at one of the Startups at Rising
                            Studio.
                        </p>
                    </div>

                    
                    <div className="flex flex-col items-center text-center">
                        <img
                            src={akshatImage}
                            alt="Akshat Birla"
                            className="w-40 h-40 rounded-full mb-4 shadow-lg object-cover"
                        />
                        <h4 className="text-xl font-bold text-gray-800">AKSHAT BIRLA</h4>
                        <p className="text-gray-600 text-sm leading-relaxed">
                            B.Com (Hons) graduate, vast experience of marketing and business
                            development at Voiceoc.
                        </p>
                    </div>

                    
                    <div className="flex flex-col items-center text-center">
                        <img
                            src={namanImage}
                            alt="Naman Malpani"
                            className="w-40 h-40 rounded-full mb-4 shadow-lg object-cover"
                        />
                        <h4 className="text-xl font-bold text-gray-800">NAMAN MALPANI</h4>
                        <p className="text-gray-600 text-sm leading-relaxed">
                            CA with an AIR-45 rank and 3 years of experience at Deloitte as a
                            Deputy Manager.
                        </p>
                    </div>

                    
                    <div className="flex flex-col items-center text-center">
                        <img
                            src={rijoImage}
                            alt="Rijo Matthew"
                            className="w-40 h-40 rounded-full mb-4 shadow-lg object-cover"
                        />
                        <h4 className="text-xl font-bold text-gray-800">RIJO MATTHEW</h4>
                        <p className="text-gray-600 text-sm leading-relaxed">
                            Past experience of Audit with Deloitte with a passion for business
                            management.
                        </p>
                    </div>

                 
                    <div className="flex flex-col items-center text-center">
                        <img
                            src={chandraImage}
                            alt="Chandra Sharma"
                            className="w-40 h-40 rounded-full mb-4 shadow-lg object-cover"
                        />
                        <h4 className="text-xl font-bold text-gray-800">
                            CHANDRA SHARMA
                        </h4>
                        <p className="text-gray-600 text-sm leading-relaxed">
                            MBA, combining academic expertise with hands-on experience to
                            optimize operations and drive efficiency.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default TeamSection;
