import React from "react";

const Footer = () => {
    return (
        <footer className="bg-gray-300 py-6">
            <div className="container mx-auto px-8 lg:px-24 flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
                {/* Left Section: Text */}
                <div className="flex-1 max-w-md">
                    <p className="text-gray-800 text-base font-bold leading-relaxed">
                        AuditScan Innovations implemented solutions to track assets,
                        inventory, finished goods, tools, fleet, delivery, consumables,
                        employees, documentation, remote sites, etc. – almost everything
                        that needs to be tracked.
                    </p>
                    <p className="text-gray-600 text-sm mt-4 font-semibold">
                        All Rights Reserved <br />
                        Unit of Lotus Enterprises
                    </p>
                </div>


                {/* Center Section: Social Media Icons */}
                <div className="flex space-x-4">
                    {/* LinkedIn Icon */}
                    <a
                        href="https://www.linkedin.com/company/auditscan/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-gray-200 p-3 rounded-full shadow hover:shadow-md transition"
                    >
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png"
                            alt="LinkedIn"
                            className="h-6 w-6 filter grayscale hover:grayscale-0"
                        />
                    </a>

                    
                    <a
                        href="https://www.youtube.com/channel/UCGpYK7vHJnyt2avwbL5Gu6Q"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-gray-200 p-3 rounded-full shadow hover:shadow-md transition"
                    >
                        <img
                            src="https://upload.wikimedia.org/wikipedia/commons/4/42/YouTube_icon_%282013-2017%29.png"
                            alt="YouTube"
                            className="h-6 w-6 filter grayscale hover:grayscale-0"
                        />
                    </a>
                </div>

                
                <div>
                    <a
                        href="https://calendly.com/auditscan_innovations/varun"
                        className="bg-blue-600 text-white font-semibold py-2 px-6 rounded-md shadow hover:bg-blue-700 transition"
                    >
                        Book A Call
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
