import React from "react";
import contentImage from "../assets/content-image.png";

const HeaderData = () => {
    return (
        <section className="bg-white py-12 mt-16" style={{
            paddingTop: "100px",
          }}>
            <div className="container mx-auto px-6 lg:px-16 flex flex-col lg:flex-row items-center lg:space-x-12">
               
                <div className="lg:w-1/2 text-center lg:text-left mb-8 lg:mb-0">
                    <h2 className="text-4xl font-bold text-gray-800 mb-6">
                        YOUR INTERNAL AUDIT DEPARTMENT
                    </h2>
                    <p className="text-lg text-gray-600 mb-4 leading-relaxed">
                        At AuditScan Innovations, we specialize in providing comprehensive
                        CARO reporting services to help companies meet their regulatory
                        obligations effectively. Our expert team meticulously examines and
                        assesses compliance with applicable laws and regulations. Through
                        our rigorous CARO reporting process, we aim to provide reliable
                        insights and ensure the integrity and credibility of financial
                        information.
                    </p>
                    <p className="text-lg text-gray-600 mb-6 leading-relaxed">
                        With AuditScan Innovations, companies can rest assured that their
                        CARO reporting requirements are handled with precision and
                        professionalism, enabling them to maintain transparency, mitigate
                        risks, and foster trust among stakeholders.
                    </p>
                  
                    <div className="flex justify-center lg:justify-start space-x-4">
                        <button
                            className="px-6 py-2 bg-[#607D8B] text-white font-bold text-lg rounded hover:bg-[#455A64]"
                            onClick={() => window.open("https://calendly.com/auditscan_innovations/varun", "_blank")}
                        >
                            Book A Call
                        </button>
                        <button className="px-6 py-2 bg-gray-200 text-gray-800 font-bold text-lg rounded hover:bg-gray-300">
                            Learn More
                        </button>
                    </div>
                </div>

                
                <div className="lg:w-1/2">
                    <img
                        src={contentImage}
                        alt="AuditScan Reporting"
                        className="w-full rounded-lg shadow-md"
                    />
                </div>
            </div>
        </section>
    );
};

export default HeaderData;
